/**
 * Post.jsx
 *
 * @file This component fetches a post from WordPress and renders it.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { CalendarToday, PersonOutline, Share, Timer } from '@mui/icons-material'
import clsx from 'clsx'
import Divider from '@mui/material/Divider'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useQuery } from '@apollo/client'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import log from 'loglevel'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import NProgress from 'nprogress'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Skeleton } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useLocation, useParams } from '@reach/router'

// internal imports
import {
	ContentNodeFragment,
	NodeWithAuthorFragment,
	NodeWithContentEditorFragment,
	NodeWithFeaturedImage_POST_THUMBNAIL_Fragment,
	NodeWithTitleFragment
} from '../../../graphql'
import { ShareBoxAlert } from '../../alerts'
import styles, { name } from './Post.styles'
import { WPFeaturedMedia } from '../'

// Create styles
const useStyles = makeStyles( styles, { name } )

/**
 * Create a component that acts as a client-only route. This component
 * fetches a blog post from WordPress and renders the post to the user.
 *
 * @param {Object} props The component props.
 * @return {Node} The rendered component.
 */
const Post = ( { wpClient } ) => {

	const logGraphQL = log.getLogger('graphql')

	const classes = useStyles()

	const WordsPerMinute = 200

	const params = useParams()

	const location = useLocation()

	/** Define a state holding the word count. */
	const [ isOpenShareBox, setOpenShareBox ] = useState( false )
	const [ wordCount, setWordCount ] = useState( 0 )

	/** Fetch all posts from the WPGraphQL Server. */
	const { data, error, loading } = useQuery( GET_POST, {
		client: wpClient,
		displayName: 'GetWPPostBySlugQuery',
		errorPolicy: 'none',
		ssr: false,
		variables: {
			uri: params.slug
		}
	} )

	useEffect( () => {
		if (typeof data !== 'undefined' && typeof data.post !== 'undefined') {
			setWordCount(data.post.content.split( /\s+/ ).length)
		}
	} )

	if ( loading )
		NProgress.start()

	else
		NProgress.done()

	if ( error ) {
		logGraphQL.error('An error occured while fetching the WordPress post', error)

		return (
			<Box sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				height: '100%',
				mb: 2,
				textAlign: 'center',
				width: '100%',
			}}>
				<Typography>Es trat ein unerwarteter Fehler auf.</Typography>
			</Box>
		)
	}

	return (
        <Box component="article">
			{ !loading && <GatsbySeo title={ data.post.title } /> }
			<Box className={ clsx( classes.header, ( !loading && data.post.featuredImage ) && classes.featuredImage ) } component="header">
				<Box className={ classes.headerInner }>
					{ !loading ?
						<Typography align="center" color="primary" dangerouslySetInnerHTML={ { __html: data.post.title } } gutterBottom variant="h2" />
					:
						<Typography align="center" color="primary" gutterBottom variant="h2"><Skeleton animation="pulse" /></Typography>
				}
					<Box className={ classes.meta }>
						<Grid container justifyContent="center">
							<Grid item xs={ 4 }>
								<Typography variant="subtitle2">
									{ !loading ? ( <><PersonOutline className={ classes.headerIcon } /> { data.post.author.node.name }</> ) : <Skeleton animation="pulse" /> }
								</Typography>
							</Grid>
							<Grid item xs={ 4 }>
								<Typography variant="subtitle2">
									{ !loading ? ( <><CalendarToday className={ classes.headerIcon } /> { moment( data.post.date ).format( 'DD. MMMM YYYY' ) }</> ) : <Skeleton animation="pulse" /> }
								</Typography>
							</Grid>
							<Grid item xs={ 4 }>
								<Typography variant="subtitle2">
									{ !loading ? ( <><Timer className={ classes.headerIcon } /> { `${ Math.ceil( wordCount / WordsPerMinute ) } Min. Lesedauer` }</> ) : <Skeleton animation="pulse" /> }
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
			{ ( !loading && data.post.featuredImage ) && <WPFeaturedMedia { ...data.post.featuredImage.node } /> }
			{ loading && <Skeleton animation="pulse" height={ 350 } variant="rectangular" width="100%" /> }
			{ ( !loading && data.post.content ) && <Box className={ classes.content } dangerouslySetInnerHTML={ { __html: data.post.content } } /> }
			{ loading && <Box><Skeleton animation="pulse" width="60%" /></Box> }
			{ !loading && (
				<Box className={ classes.footer } component="footer">
					<Box className={ classes.footerInner }>
						<Divider />
						<Toolbar className={ classes.authorToolbarRoot }>
							<Avatar alt={ data.post.author.node.name } className={ classes.authorAvatar } src={ data.post.author.node.avatar.url } />
							<Typography className={ classes.authorName } component="div" variant="h5">
								Von { data.post.author.node.url ? <Link
                                href={ data.post.author.node.url }
                                rel="noopener noreferrer"
                                target="_blank"
                                underline="hover">{ data.post.author.node.name }</Link> : data.post.author.node.name }
							</Typography>
							<Tooltip title="Artikel teilen...">
								<IconButton
                                    color="secondary"
                                    onClick={ e => { setOpenShareBox( true ) } }
                                    size="large">
									<Share />
								</IconButton>
							</Tooltip>
						</Toolbar>
						{ data.post.author.node.description && (
							<Box className={ classes.authorDescription }>
								<Typography variant="subtitle1">{ data.post.author.node.description }</Typography>
							</Box>
						) }
					</Box>
					<ShareBoxAlert
						onClose={ () => { setOpenShareBox( false ) } }
						open={ isOpenShareBox }
						text={ `Lesen Sie den Artikel über ${ data.post.title } auf der Website von ${ process.env.GATSBY_SITE_NAME }: ` }
						url={ location.href } />
				</Box>
			) }
		</Box>
    )

}

/** Define some fragments. */
Post.fragments = {
	author: NodeWithAuthorFragment,
	content: NodeWithContentEditorFragment,
	featuredImage: NodeWithFeaturedImage_POST_THUMBNAIL_Fragment
}

/** Query to get a blog post from the server. */
const GET_POST = gql`
	query GetWPPost( $uri: ID! ) {
    	post( id: $uri, idType: URI ) {
			...ContentNodeFragment
			...NodeWithAuthorFragment
			...NodeWithContentEditorFragment
			...NodeWithFeaturedImage_POST_THUMBNAIL_Fragment
			...NodeWithTitleFragment
		}
	}
	${ Post.fragments.author }
	${ Post.fragments.content }
	${ Post.fragments.featuredImage }
	${ ContentNodeFragment }
	${ NodeWithTitleFragment }
`

/**
 *
 */
Post.propTypes = {
	wpClient: PropTypes.object.isRequired
}

export default Post
