/**
 * veranstaltungen.jsx
 *
 * @file Veranstaltungen page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { ApolloClient, gql, HttpLink, InMemoryCache, useQuery } from '@apollo/client'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import fetch from 'cross-fetch' // In order to make @apollo/client work when Gatsby renders on the server
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import log from 'loglevel'
import moment from 'moment'
import NProgress from 'nprogress'
import React from 'react'
import { Router } from '@reach/router'
import Typography from '@mui/material/Typography'

// internal imports
import {
	ContentNodeFragment,
	NodeWithFeaturedImage_MEDIUM_Fragment,
	NodeWithTitleFragment
} from '../graphql'
import possibleTypes from '../data/possibleTypes.json'
import { WPExcerpt, WPPost } from '../components/WordPress'

const wpClient = new ApolloClient( {
	cache: new InMemoryCache( {
		possibleTypes
	} ),
	link: new HttpLink( {
/* 		credentials: 'include', */
		fetch,
		uri: process.env.GATSBY_APOLLO_CLIENT_WP_ENDPOINT
	} )
} )

/** Query to get a blog post from the server. */
const GET_WPPOSTFEED = gql`
	query GetWPPostFeed( $after: String, $before: String, $first: Int, $last: Int ) {
    	posts( after: $after, before: $before, first: $first, last: $last, where: { status: PUBLISH } ) {
			edges {
				cursor
				node {
					...ContentNodeFragment
					...NodeWithExcerptFragment
					...NodeWithFeaturedImage_MEDIUM_Fragment
					...NodeWithTitleFragment
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		}
	}
	${ ContentNodeFragment }
	${ NodeWithFeaturedImage_MEDIUM_Fragment }
	${ NodeWithTitleFragment }
	${ WPExcerpt.fragments.node }
`

/**
 * Create a component that acts as a client-only route. This component
 * fetches all blog posts from WordPress and renders the posts as excerpts
 * to the user.
 *
 * @param {Object} props The component props.
 * @return {Node} The rendered component.
 */
const Feed = () => {

	const logGraphQL = log.getLogger('graphql')

	/** Fetch all posts from the WPGraphQL Server. */
	const { data, fetchMore, error, loading } = useQuery( GET_WPPOSTFEED, {
		client: wpClient,
		displayName: 'GetWPPostsQuery',
		errorPolicy: 'ignore',
		fetchPolicy: 'cache-and-network',
		ssr: false,
		variables: {
			after: null,
			before: null,
			first: 10,
			last: null
		}
	} )

	if ( loading ) {
		NProgress.start()

		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<CircularProgress
					size={ 50 }
					sx={{ color: 'secondary.main' }} />
			</Box>
		)
	}

	NProgress.done()

	if ( error ) {
		logGraphQL.error('An error occured while fetching posts from WordPress', error)

		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>Es trat ein unerwarteter Fehler auf.</Typography>
			</Box>
		)
	}

	if ( data.posts.edges.length === 0 )
		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>
					Zur Zeit gibt es keine Veranstaltungen, die wir Ihnen vorstellen möchten.
				</Typography>
			</Box>
		)

	return (
		<>
			<Typography align="center" color="primary" gutterBottom variant="h4">Veranstaltungen</Typography>
			{ data.posts.edges.map( ( { node: post } ) => (
				<React.Fragment key={ post.id }>
					<WPExcerpt
						date={ moment( post.date ).format( 'DD. MMMM YYYY' ) }
						excerpt={ post.excerpt }
						featuredImage={ post.featuredImage ? post.featuredImage.node : null }
						slug={ post.slug }
						title={ post.title } />
					<Divider variant="middle" />
				</React.Fragment>
			) ) }
			<Box
				sx={{
					alignContent: 'space-between',
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					my: 2.5,
					p: 1.25
				}}>
				<Button
					color="primary"
					disabled={ !data.posts.pageInfo.hasPreviousPage }
					onClick={ () => {
						NProgress.start()
						fetchMore( {
							updateQuery: ( prev, { fetchMoreResult } ) => {
								const newEdges = fetchMoreResult.posts.edges
								const pageInfo = fetchMoreResult.posts.pageInfo

								NProgress.done()

								return newEdges.length ?
									{
										posts: {
											__typename: prev.posts.__typename,
											edges: [ ...newEdges ],
											pageInfo
										}
									}
								: prev
							},
							variables: {
								after: null,
								before: data.posts.pageInfo.startCursor,
								first: null,
								last: 10
							}
						} )
					} }
					variant="outlined">Vorherige Seite</Button>
				<Button
					color="primary"
					disabled={ !data.posts.pageInfo.hasNextPage }
					onClick={ () => {
						NProgress.start()
						fetchMore( {
							updateQuery: ( prev, { fetchMoreResult } ) => {
								const newEdges = fetchMoreResult.posts.edges
								const pageInfo = fetchMoreResult.posts.pageInfo

								NProgress.done()

								return newEdges.length ?
									{
										posts: {
											__typename: prev.posts.__typename,
											edges: [ ...newEdges ],
											pageInfo
										}
									}
								: prev
							},
							variables: {
								after: data.posts.pageInfo.endCursor,
								before: null,
								first: 10,
								last: null
							}
						} )
					} }
					variant="outlined">Nächste Seite</Button>
			</Box>
		</>
	)

}

/**
 * This component represents the Veranstaltungen page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const VeranstaltungenPage = () => {

	return (
		<>
			<GatsbySeo title="Veranstaltungen" />
			<Router basepath="/veranstaltungen">
				<WPPost path="/:slug" wpClient={ wpClient } />
				<Feed path="/" />
			</Router>
			<Box sx={{ mb: 2, mt: 4 }} />
		</>
	)
}

export default VeranstaltungenPage
