/**
 * ContentNodeFragment.js
 *
 * @file This file exports a GraphQL fragment to use specific fields
 * for the `ContentNode` union type.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { gql } from '@apollo/client'

export default gql`
	fragment ContentNodeFragment on ContentNode {
		databaseId
		date
		id
		modified
		slug
	}
`
