/**
 * FeaturedMedia.jsx
 *
 * @file This file exports a component to render a featured image from a WordPress blog post.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import styles, { name } from './FeaturedMedia.styles'

// Create styles
const useStyles = makeStyles( styles, { name } )

/**
 * Create a component that renders WordPress blog posts featured images.
 *
 * @param {Object} props The component props.
 * @return {Node} The rendered component.
 */
const FeaturedMedia = props => {

	const {
		altText,
		caption,
		id,
		inExcerpt,
		mediaDetails,
		mediaItemUrl,
		mediaType,
		mimeType,
		sizes,
		sourceUrl,
		srcSet,
		...other
	} = props

	const classes = useStyles()

	let renderedImage = (
		<img
			alt={ altText }
			className={ classes.img }
			height={ mediaDetails.height }
			sizes={ sizes }
			src={ sourceUrl }
			srcSet={ srcSet }
			width={ mediaDetails.width }
			{ ...other } />
	)

	const wordCount = caption.split( /\s+/ ).length

	return (
		<Box className={ classes.wrapper } component="figure" data-wordcount={ wordCount }>
			<Box className={ clsx( classes.inner, inExcerpt && classes.excerpt ) }>
				{ renderedImage }
				{ caption &&
					<Typography
						align="center"
						className={ classes.caption }
						color="textSecondary"
						component="figcaption"
						dangerouslySetInnerHTML={ { __html: caption } }
						gutterBottom
						variant="caption" />
				}
			</Box>
		</Box>
	)

}

/**
 *
 */
FeaturedMedia.propTypes = {
	altText: PropTypes.string,
	caption: PropTypes.string,
	id: PropTypes.string.isRequired,
	inExcerpt: PropTypes.bool,
	mediaDetails: PropTypes.shape( {
		file: PropTypes.string,
		height: PropTypes.number,
		width: PropTypes.number
	} ),
	mediaItemUrl: PropTypes.string,
	mediaType: PropTypes.string,
	mimeType: PropTypes.string,
	sizes: PropTypes.string,
	sourceUrl: PropTypes.string,
	srcSet: PropTypes.string
}

export default FeaturedMedia
