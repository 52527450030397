/**
 * NodeWithAuthorFragment.js
 *
 * @file This file exports a GraphQL fragment to use specific fields
 * for the `NodeWithAuthor` union type.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { gql } from '@apollo/client'

export default gql`
	fragment NodeWithAuthorFragment on NodeWithAuthor {
		author {
			node {
				avatar( size: 96 rating: G ) {
					default
					foundAvatar
					height
					scheme
					size
					url
					width
				}
				description
				firstName
				id
				lastName
				name
				nicename
				nickname
				url
			}
		}
		authorDatabaseId
		authorId
	}
`
