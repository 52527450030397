/**
 * Excerpt.jsx
 *
 * @file This component creates an excerpt for blog posts.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@mui/material/Typography'

// internal imports
import { ButtonLink } from '../../ButtonLink'
import { FeaturedMedia } from '../FeaturedMedia'
import { NodeWithExcerptFragment } from '../../../graphql'
import routes from '../../../data/routes.json'

/**
 * Create a component that renders WordPress blog post excerpts.
 *
 * @param {Object} props The component props.
 * @return {Node} The rendered component.
 */
const Excerpt = ( { date, excerpt, featuredImage, slug, title } ) => {

	let renderedExcerpt = null

	if ( excerpt )
		renderedExcerpt = <Box
			dangerouslySetInnerHTML={ { __html: excerpt } }
			sx={{
				display: 'block',
				mb: 2,
				mt: 1
			}} />
	else
		renderedExcerpt = (
			<Typography
				paragraph
				sx={{
					display: 'block',
					mb: 2,
					mt: 1
				}}
				variant="body1">
				Leider steht kein Auszug des Artikels zur Verfügung.
			</Typography>
		)

	return (
		<Box component="article" sx={{ pl: 3, py: 2 }}>
			<Typography align="center" dangerouslySetInnerHTML={ { __html: title } } gutterBottom variant="h5" />
			<Typography align="right" color="textSecondary" gutterBottom variant="overline" sx={{ display: 'block', mr: 2.5 }}>{ date }</Typography>
			{ featuredImage && <FeaturedMedia inExcerpt { ...featuredImage } /> }
			{ renderedExcerpt }
			<Box sx={{ textAlign: 'center' }}>
				<ButtonLink color="primary" to={ `${ routes[ 2 ].path }${ slug }/` } variant="text">Mehr lesen</ButtonLink>
			</Box>
		</Box>
	)

}

Excerpt.fragments = {
	node: NodeWithExcerptFragment
}

/**
 *
 */
Excerpt.propTypes = {
	date: PropTypes.string.isRequired,
	excerpt: PropTypes.string,
	featuredImage: PropTypes.shape( {
		altText: PropTypes.string,
		caption: PropTypes.string,
		id: PropTypes.string.isRequired,
		mediaDetails: PropTypes.shape( {
			file: PropTypes.string,
			height: PropTypes.number,
			width: PropTypes.number
		} ),
		mediaItemUrl: PropTypes.string,
		mediaType: PropTypes.string,
		mimeType: PropTypes.string,
		sizes: PropTypes.string,
		sourceUrl: PropTypes.string,
		srcSet: PropTypes.string
	} ),
	slug: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
}

export default Excerpt
