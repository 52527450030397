/**
 * FeaturedMedia.styles.js
 *
 * @file Define the styles for the `FeaturedMedia` component.
 * @author Robin Walter <hello@robinwalter.me>
 */

export const name = 'JDWPFeaturedMedia'

export default theme => ( {
	caption: {
		margin: [ [ theme.spacing( 2.5 ), 'auto', 0, 'auto' ] ]
	},
	excerpt: {
		maxWidth: '36.25rem !important',
		width: `calc( 100% - ${ theme.spacing( 5 ) }px ) !important`
	},
	img: {
		display: 'block',
		height: 'auto',
		margin: [ [ 0, 'auto' ] ],
		maxWidth: '100%'
	},
	inner: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '75rem',
		position: 'relative'
	},
	wrapper: {
		marginBottom: theme.spacing( 1 ),
		marginTop: theme.spacing( 1 ),
		position: 'relative'
	}
} )
