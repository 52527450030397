/**
 * Post.styles.js
 *
 * @file Define the styles for the `Post` component.
 * @author Robin Walter <hello@robinwalter.me>
 */

export const name = 'JDWPPost'

export default theme => ( {
	authorAvatar: {
		height: 48,
		width: 48
	},
	authorDescription: {
		marginTop: theme.spacing( 1 ),
		paddingLeft: theme.spacing( 10 ),
		paddingRight: theme.spacing( 1 )
	},
	authorName: {
		flex: '1 1 100%',
		paddingLeft: theme.spacing( 2 )
	},
	authorToolbarRoot: {
		marginTop: theme.spacing( 1 ),
		paddingLeft: theme.spacing( 2 ),
		paddingRight: theme.spacing( 1 )
	},
	content: {
		'&:after': {
			clear: 'both',
			content: '""',
			display: 'block'
		},
		'& > *': {
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		'& > :not( [class*="alignfull"] ):not( [class*="alignleft"] ):not( [class*="alignright"] ):not( [class*="alignwide"] )': {
			maxWidth: '36.25rem',
			width: `calc( 100% - ${ theme.spacing( 5 ) }px )`
		},
		'& > :last-child': {
			marginBottom: 0
		}
	},
	featuredImage: {
		paddingBottom: `${ theme.spacing( 2 ) }px !important`,
		[ theme.breakpoints.up( 'md' ) ]: {
			paddingBottom: `${ theme.spacing( 4 ) }px !important`
		}
	},
	footer: {
		marginTop: theme.spacing( 15 ),
		padding: [ [ theme.spacing( 5 ), 0 ] ],
		[ theme.breakpoints.up( 'md' ) ]: {
			padding: [ [ theme.spacing( 10 ), 0 ] ]
		}
	},
	footerInner: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '36.25rem',
		width: `calc( 100% - ${ theme.spacing( 5 ) }px )`
	},
	header: {
		padding: [ [ theme.spacing( 5 ), 0 ] ],
		textAlign: 'center',
		[ theme.breakpoints.up( 'md' ) ]: {
			padding: [ [ theme.spacing( 10 ), 0 ] ]
		}
	},
	headerIcon: {
		bottom: -2,
		fontSize: '0.95rem',
		position: 'relative'
	},
	headerInner: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: theme.spacing( 125 ),
		[ theme.breakpoints.up( 'sm' ) ]: {
			width: `calc( 100% - ${ theme.spacing( 10 ) }px )`
		}
	},
	meta: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '36.25rem',
		width: '100%',
		[ theme.breakpoints.up( 'sm' ) ]: {
			marginTop: theme.spacing( 4 )
		}
	}
} )
