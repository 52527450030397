/**
 * NodeWithContentEditorFragment.js
 *
 * @file This file exports a GraphQL fragment to use specific fields
 * for the `NodeWithContentEditor` union type.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { gql } from '@apollo/client'

export default gql`
	fragment NodeWithContentEditorFragment on NodeWithContentEditor {
		content( format: RENDERED )
	}
`
