/**
 * NodeWithFeaturedImage_MEDIUM_Fragment.js
 *
 * @file This file exports a GraphQL fragment to use specific fields
 * for the `NodeWithFeaturedImage` union type. `MEDIUM` is used for the
 * `size` attribute.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { gql } from '@apollo/client'

export default gql`
	fragment NodeWithFeaturedImage_MEDIUM_Fragment on NodeWithFeaturedImage {
		featuredImage {
			node {
				altText
				caption
				id
				mediaDetails {
					file
					height
					width
				}
				mediaItemUrl
				mediaType
				mimeType
				sizes( size: MEDIUM )
				sourceUrl( size: MEDIUM )
				srcSet( size: MEDIUM )
			}
		}
		featuredImageId
	}
`
