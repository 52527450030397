/**
 * NodeWithTitleFragment.js
 *
 * @file This file exports a GraphQL fragment to use specific fields
 * for the `NodeWithTitle` union type.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { gql } from '@apollo/client'

export default gql`
	fragment NodeWithTitleFragment on NodeWithTitle {
		title( format: RENDERED )
	}
`
